<template>
  <div class="d-flex  ">
    <div style="height: 100vh;" class="rules-filters auto-box shadow-sm rounded-sm  " :class="{ collapsed }">
      <div class="auto-box-inner">
        <h4 class="mb-5">Rules And Filters</h4>
        <b-form-group label-for="keywords">
          <template slot="label">
            Enter your search keywords
          </template>
          <vue-tags-input
            v-model="keywordInput"
            :tags="keywords"
            :add-on-key="[13, ',', ';']"
            :allow-edit-tags="true"
            :placeholder="
              keywords.length ? 'Add keywords' : 'Enter your main keywords or phrases, e.g. Social Media, Big Data...'
            "
            @tags-changed="handleKeywordsChange"
          />
        </b-form-group>

        <h5
          @click="toggleShowRefineQuery"
          class="refine-query-header mb-3 mt-5"
          :class="{ collapsed: !showRefineQuery }"
        >
          Refine Your Query
          <svg-icon :name="`chevron-${showRefineQuery ? 'up' : 'down'}`" />
        </h5>
        <div class="refine-query-body" :class="{ show: showRefineQuery }">
          <b-form-group label-for="exclude-keywords">
            <template slot="label">
              Exclude keywords
            </template>
            <vue-tags-input
              id="exclude-keywords"
              v-model="excludeKeywordInput"
              :tags="excludeKeywords"
              :add-on-key="[13, ',', ';']"
              :allow-edit-tags="true"
              :placeholder="
                excludeKeywords.length ? 'Add keywords' : 'Enter keywords to exclude to remove irrelevant results'
              "
              @tags-changed="handleExcludeKeywordsChange"
            />
          </b-form-group>
        </div>

        <b-row class="mt-4">
          <b-col align-self="center" md="3"> <label for="region">Region</label> </b-col>
          <b-col md="6">
            <b-form-select :value="region" @change="handleRegionChange" class="filter-input">
              <option v-for="region in regions" :key="region.code" :value="region.code">{{ region.name }}</option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col align-self="center" md="3"> <label for="region">Post Language</label> </b-col>
          <b-col md="6">
            <b-form-select :value="language" @change="handleLanguageChange" class="filter-input">
              <option v-for="lang in langs" :key="lang.code" :value="lang.code">{{ lang.name }}</option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col align-self="center" md="3"> <label for="post-age">Post Age</label> </b-col>
          <b-col md="6">
            <b-form-select :value="postAge" @change="handlePostAgeChange" id="post-age">
              <option value="any">Any</option> <option value="1">Last 24 hours</option>
              <option value="3">Last 3 Days</option> <option value="7">Last 7 Days</option>
              <option value="14">Last 14 Days</option> <option value="30">Last 30 Days</option>
              <option value="custom">After a specific date</option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row v-show="postAge === 'custom'" class="mt-4">
          <b-col align-self="center" md="3"> <label for="post-age">Specific date</label> </b-col>
          <b-col md="6">
            <date-range-picker
              opens="center"
              :ranges="false"
              :locale-data="null"
              :minDate="null"
              :maxDate="fromAfterDateMax"
              :singleDatePicker="true"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              :dateRange="fromAfterDate"
              :linkedCalendars="true"
              class="mr-2"
              @update="handleFromAfterDateChange"
            >
              <div slot="input" slot-scope="picker" class="px-3" :style="{ minWidth: '200px' }">
                <svg-icon name="calendar" class="mr-1" />
                {{ picker.startDate | date }}
              </div>
            </date-range-picker>
          </b-col>
        </b-row>
      </div>
    </div>

    <transition name="preview-slide">
      <div v-if="collapsed" class="article-preview rounded-sm shadow-sm px-5">
        <div class="preview-header text-center"><h4>Result Preview</h4></div>
        <simplebar class="preview-scroll">
          <p class="preview-desc">
            This is the type of content you will get through this automation campaign. You should mainly look if content
            presented to you is matching your criteria, if not, you can exclude the irrelevant results by using exclude
            feature.
          </p>

          <div class="preview-items-wrap">
            <div v-if="isPreviewContentsLoading" class="text-center"><b-spinner variant="primary" /></div>
            <div
              v-else-if="previewContents.articles && previewContents.articles.length > 0 && !isPreviewContentsLoading"
            >
              <div v-for="post in previewContents.articles" :key="post.id" class="preview-item">
                <div class="d-flex">
                  <lazy-background-image :src="post.image" class="preview-item-image" />
                  <div>
                    <h5 class="preview-item-title">{{ descape(post.title) }}</h5>
                    <div v-html="descape(post.title)" class="preview-item-content"></div>
                    <div class="preview-item-domain text-muted">{{ post.domain }}</div>
                    <div class="preview-item-time text-muted">{{ post.publishDate | timeAgo }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="previewContents.articles && previewContents.articles.length < 1 && !isPreviewContentsLoading"
              class="mt-4 text-center"
            >
              <img class="my-4" width="200" src="@/assets/images/not_found.svg" alt="" />
              <p>
                Nothing found. <br />
                Try updating your search parameters
              </p>
            </div>
          </div>
        </simplebar>
      </div>
    </transition>
  </div>
</template>

<script>
import { decode } from 'he';
import moment from 'moment';
import { mapState } from 'vuex';
import VueTagsInput from '@johmun/vue-tags-input';

import LazyBackgroundImage from '~/components/LazyBackgroundImage';
import { langSub } from '~/utils/languages';
import regions from '~/utils/regions';
import { ARTICLE_SEARCH_QUERY } from '~/graphql/queries';

import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  components: {
    simplebar,
    VueTagsInput,
    DateRangePicker,
    LazyBackgroundImage,
  },

  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      regions: regions,
      langs: langSub,

      scrollY: 0,

      showRefineQuery: false,

      fromAfterDateMax: moment().format('YYYY-MM-DD'),

      keywordInput: '',
      includeKeywordInput: '',
      excludeKeywordInput: '',
      excludeDomainInput: '',
      limitDomainInput: '',

      domainValidation: [
        {
          classes: 'invalid-domain',
          rule: tag => {
            const regex = new RegExp(/^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/);
            return !tag.text.match(regex);
          },
        },
      ],
    };
  },

  computed: {
    ...mapState('createAutomation', {
      keywords: state => state.keywords,
      excludeKeywords: state => state.excludeKeywords,
      region: state => state.region,
      language: state => state.language,
      postAge: state => state.postAge,
      fromAfterDate: state => state.fromAfterDate,

      previewContents: state => state.previewContents,
      isPreviewContentsLoading: state => state.isPreviewContentsLoading,
    }),
  },

  filters: {
    date(value) {
      if (!value) return '';

      return moment(value).format('MMMM D, YYYY');
    },

    timeAgo: function(date) {
      return moment(date).fromNow();
    },
  },

  mounted() {
    if (this.keywords.length) {
      this.showRefineQuery = true;
    }

    window.addEventListener('scroll', this.scrollHandler);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },

  methods: {
    scrollHandler() {
      this.scrollY = Math.round(window.scrollY);
    },

    fetchPosts() {
      if (this.keywords.length < 1) {
        return;
      }

      const variables = {
        keywords: this.keywords.map(t => t.text),
        excludeKeywords: this.excludeKeywords.map(t => t.text),
        region: this.region,
        language: this.language,
        postAge: this.postAge,
        fromAfterDate: this.fromAfterDate.startDate,
      };

      if (!this.$apollo.queries.previewContents) {
        this.$apollo.addSmartQuery('previewContents', {
          query: ARTICLE_SEARCH_QUERY,
          variables,
          update(data) {
            // The returned value will update
            // the vue property 'pingMessage'
            this.$store.dispatch('createAutomation/updatePreviewContents', data.articleSearch);
          },
          watchLoading(isLoading) {
            // isLoading is a boolean
            // countModifier is either 1 or -1
            this.$store.dispatch('createAutomation/updateIsPreviewContentsLoading', isLoading);
          },
        });
      } else {
        this.$apollo.queries.previewContents.refetch(variables);
      }
    },

    toggleShowRefineQuery() {
      this.showRefineQuery = !this.showRefineQuery;
    },

    handleKeywordsChange(keywords) {
      this.$store.dispatch('createAutomation/updateKeywords', keywords);

      this.fetchPosts();
    },

    handleExcludeKeywordsChange(excludeKeywords) {
      this.$store.dispatch('createAutomation/updateExcludeKeywords', excludeKeywords);

      this.fetchPosts();
    },

    handleRegionChange(region) {
      this.$store.dispatch('createAutomation/updateRegion', region);

      this.fetchPosts();
    },

    handleLanguageChange(language) {
      this.$store.dispatch('createAutomation/updateLanuage', language);

      this.fetchPosts();
    },

    handlePostAgeChange(value) {
      this.$store.dispatch('createAutomation/updatePostAge', value);

      if (value !== 'custom') {
        this.fetchPosts();
      }
    },

    handleFromAfterDateChange(dateObj) {
      this.$store.dispatch('createAutomation/updateFromAfterDate', dateObj);

      this.fetchPosts();
    },

    descape(text) {
      return decode(text);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.rules-filters {
  .vue-tags-input {
    max-width: 100% !important;
  }

  .form-control {
    &::placeholder {
      color: $gray-400;
      font-size: 0.75rem;
    }
  }

  .refine-query-header {
    font-weight: normal !important;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-bottom: 1px solid $gray-200;
    padding-bottom: 10px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease-out;

    &.collapsed {
      padding: 1rem;
      border: 1px solid $gray-200;
      border-radius: 0.4rem;
    }
  }

  .refine-query-body {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-out;

    &.show {
      max-height: 500px;
    }
  }
}
.article-preview {
  width: 35%;
  height: 100vh;
  background-color: $white;
  position: absolute;
  right: 0;

  &.stick {
    height: calc(100vh - #{$navbar-height + $topbar-height});
    position: fixed;
    top: $navbar-height + $topbar-height;
    border-radius: 0 !important;
  }

  .preview-scroll {
    overflow-y: auto;
    height: 92%;
  }

  .preview-header {
    border-bottom: 1px solid $gray-100;
    padding: 0.7rem;
    color: $gray-500;
    h4 {
      margin-bottom: 0;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
  .preview-desc {
    font-size: 0.9rem;
    padding: 0.7rem 1rem;
    text-align: center;
  }

  .preview-items-wrap {
    padding: 1px 1rem;
  }
  .preview-item {
    box-shadow: 0 1px 5px $gray-200;
    margin-bottom: 15px;
    padding: 10px;
    background-color: lighten(azure, 2);

    .preview-item-image {
      background-size: cover;
      width: 250px;
      height: 100px;
      margin-right: 15px;
    }

    .preview-item-title {
      font-size: 0.8rem;
      font-weight: bold;
    }
    .preview-item-domain,
    .preview-item-time,
    .preview-item-content {
      font-size: 0.7rem;
    }

    .preview-item-content {
      .highlighted {
        display: inline-block;
        background-color: rgba($primary, 0.4);
        padding: 0 1px;
      }
    }

    .preview-item-domain,
    .preview-item-time {
      margin-top: 5px;
      color: $gray-100;
    }
  }
}
.preview-slide-enter-active {
  animation: preview-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.preview-slide-leave-active {
  animation: preview-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes preview-slide-left {
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes preview-slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(475px);
  }
}
</style>
